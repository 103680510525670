import { Component, OnInit } from '@angular/core';
import { LoadingSpinnerService } from '@app/core/services/loading-spinner.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  standalone: false
})
export class LoadingSpinnerComponent implements OnInit {

  isHidden$: Observable<boolean>;

  constructor(private loadingSpinner: LoadingSpinnerService) { }

  ngOnInit(): void {
    this.isHidden$ = this.loadingSpinner.isHidden();
  }

}
