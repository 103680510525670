import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationBannerService } from '@app/core/services/notification-banner.service';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';

enum StateEnum {
  visible = 'visible',
  hidden = 'hidden'
}

@Component({
  selector: 'app-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.scss'],
  animations: [
    trigger('changeState', [
      state('visible', style({
        transform: 'translateY(80px)' // negative margin top in css
      })),
      state('hidden', style({
        transform: 'translateY(0px)'
      })),
      transition('*=>visible', animate('0.25s ease-out')),
      transition('*=>hidden', animate('0.5s ease-in'))
    ])
  ],
  standalone: false
})
export class NotificationBannerComponent implements OnInit, OnDestroy {

  readonly VISIBLE_TIMEOUT = 3000; // milliseconds

  bannerSubscription: Subscription;
  text: string;
  state = StateEnum.hidden;

  constructor(private notificationBanner: NotificationBannerService) {
  }

  ngOnInit() {
    this.bannerSubscription = this.notificationBanner.getText().subscribe(text => {
      this.text = text;
      this.state = StateEnum.visible;
      setTimeout(() => {
        this.state = StateEnum.hidden;
      }, this.VISIBLE_TIMEOUT);
    });
  }

  ngOnDestroy(): void {
    if (this.bannerSubscription) {
      this.bannerSubscription.unsubscribe();
    }
  }

}
