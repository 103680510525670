import { BrowserModule } from '@angular/platform-browser';
import { NgModule, inject, provideAppInitializer } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LoadingIndicatorModule } from '@aviatar/loading-indicator';
import { ControlCenterApiModule } from '@app/gen/control-center-api/control-center-api.module';
import { SharedModule } from '@app/shared/shared.module';
import { IncidentStatusModule } from '@aviatar/incident-status';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@src/environments/environment';
import { ProcessEngineResolverService } from '@app/core/services/process-engine-resolver.service';
import { TagMapperService } from '@app/core/services/tag-mapper.service';
import { TaskDataModule } from '@app/task-data/task-data.module';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { UnsupportedBrowserWarningModule } from '@aviatar/unsupported-browser-warning';
import { ReleaseNotesButtonComponent } from '@aviatar/release-notes';

dayjs.extend(utc);
dayjs.extend(tz);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    IncidentStatusModule,
    LoadingIndicatorModule,
    UnsupportedBrowserWarningModule,
    ControlCenterApiModule,
    ReleaseNotesButtonComponent,
    SharedModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, connectInZone: true }),
    TaskDataModule
  ],
  providers: [
    provideAppInitializer(() => {
      const initializerFn = ((resolverService: ProcessEngineResolverService, tagMapper: TagMapperService) =>
        () => Promise.all([tagMapper.initialize().toPromise(), resolverService.initialize().toPromise()]))(inject(ProcessEngineResolverService), inject(TagMapperService));
      return initializerFn();
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
