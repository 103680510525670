import * as i0 from '@angular/core';
import { inject, Component, Input, NgZone, Injectable } from '@angular/core';
import { mdiFileDocumentEdit } from '@mdi/js';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { IconComponent } from '@aviatar/icon';
import { map, finalize, switchMap, concatMap, catchError, reduce } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, of, tap, from, EMPTY, shareReplay, switchMap as switchMap$1, timer } from 'rxjs';
import { ReleaseNotesAttachmentsService, ReleaseNotesService, SubjectService } from '@aviatar/api-client';
import { UserTrackingService } from '@aviatar/user-tracking';
function ReleaseNotesButtonComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 2)(1, "span", 3);
    i0.ɵɵtext(2, "New release notes");
    i0.ɵɵelementEnd()();
  }
}
class ReleaseNotesModalComponent {
  constructor() {
    this.activeModal = inject(NgbActiveModal);
  }
  ngOnInit() {
    this.content = (this.releaseNotes?.notes || '') + '<br>' + (this.releaseNotes?.footer || '');
  }
  static {
    this.ɵfac = function ReleaseNotesModalComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ReleaseNotesModalComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ReleaseNotesModalComponent,
      selectors: [["aviatar-release-notes-modal"]],
      inputs: {
        releaseNotes: "releaseNotes"
      },
      decls: 12,
      vars: 7,
      consts: [[1, "modal-header"], [1, "modal-title"], ["type", "button", "aria-label", "Close", 1, "btn-close", 3, "click"], [1, "modal-body"], [1, "mx-4", 3, "innerHTML"], [1, "modal-footer", "border-top"], ["type", "button", 1, "btn", "btn-primary", 3, "click"]],
      template: function ReleaseNotesModalComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "h4", 1);
          i0.ɵɵtext(2, "Release Note");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "button", 2);
          i0.ɵɵlistener("click", function ReleaseNotesModalComponent_Template_button_click_3_listener() {
            return ctx.activeModal.close();
          });
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(4, "div", 3)(5, "h2");
          i0.ɵɵtext(6);
          i0.ɵɵpipe(7, "date");
          i0.ɵɵelementEnd();
          i0.ɵɵelement(8, "div", 4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(9, "div", 5)(10, "button", 6);
          i0.ɵɵlistener("click", function ReleaseNotesModalComponent_Template_button_click_10_listener() {
            return ctx.activeModal.close();
          });
          i0.ɵɵtext(11, "Close");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(6);
          i0.ɵɵtextInterpolate2("Version ", ctx.releaseNotes.version, " - released ", i0.ɵɵpipeBind3(7, 3, ctx.releaseNotes.publishedAt, "yyyy-MM-dd", "UTC"), "");
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("innerHTML", ctx.content, i0.ɵɵsanitizeHtml);
        }
      },
      dependencies: [DatePipe],
      styles: ["[_nghost-%COMP%]  .modal-body img, [_nghost-%COMP%]  .modal-body video{max-width:100%}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ReleaseNotesModalComponent, [{
    type: Component,
    args: [{
      selector: 'aviatar-release-notes-modal',
      imports: [DatePipe],
      template: "<div class=\"modal-header\">\n    <h4 class=\"modal-title\">Release Note</h4>\n    <button type=\"button\" class=\"btn-close\" aria-label=\"Close\" (click)=\"activeModal.close()\"></button>\n</div>\n<div class=\"modal-body\">\n    <h2>Version {{ releaseNotes.version }} - released {{ releaseNotes.publishedAt | date:'yyyy-MM-dd':'UTC' }}</h2>\n    <div class=\"mx-4\" [innerHTML]=\"content\"></div>\n</div>\n<div class=\"modal-footer border-top\">\n    <button type=\"button\" class=\"btn btn-primary\" (click)=\"activeModal.close()\">Close</button>\n</div>\n",
      styles: [":host::ng-deep .modal-body img,:host::ng-deep .modal-body video{max-width:100%}\n"]
    }]
  }], null, {
    releaseNotes: [{
      type: Input,
      args: [{
        required: true
      }]
    }]
  });
})();
class BlobService {
  constructor() {
    this.attachmentsService = inject(ReleaseNotesAttachmentsService);
    this.zone = inject(NgZone);
    this.blobIds = new Map();
    this.loadingMedia$ = new BehaviorSubject(false);
  }
  static {
    this.BLOB_MATCH_EXP = /src="blob:([^"]+)"/g;
  }
  static {
    this.UUID_STRING_LENGTH = 36;
  }
  getLoadingMedia$() {
    return this.loadingMedia$.asObservable();
  }
  setLoadingMedia(state) {
    this.zone.run(() => this.loadingMedia$.next(state));
  }
  processUrlsInReleaseNotes(source) {
    return forkJoin([of(source), this.processUrlsInString(source.notes), source.footer ? this.processUrlsInString(source.footer) : of(source.footer)]).pipe(map(([releaseNotes, notes, footer]) => ({
      ...releaseNotes,
      notes,
      footer
    })), finalize(() => this.setLoadingMedia(false)));
  }
  processUrlsInString(source) {
    const attachmentIds = this.findBlobIds(source);
    if (attachmentIds.length === 0) {
      return of(source);
    }
    return of({}).pipe(tap(() => this.setLoadingMedia(true)), switchMap(() => from(attachmentIds)), concatMap(id => this.attachmentsService.getReleaseNotesAttachment({
      attachmentId: id
    }).pipe(catchError(() => EMPTY), map(blob => ({
      id,
      blob
    })))), map(({
      id,
      blob
    }) => ({
      id,
      url: URL.createObjectURL(blob)
    })), tap(({
      id,
      url
    }) => this.blobIds.set(url.slice(-BlobService.UUID_STRING_LENGTH), id)), reduce((acc, curr) => acc.replace(new RegExp(`src="blob:${curr.id}"`, 'g'), `src="${curr.url}"`), source));
  }
  findBlobIds(notes) {
    return notes.match(BlobService.BLOB_MATCH_EXP)?.map(this.idFromMatch) || [];
  }
  idFromMatch(match) {
    const uuidIndexStart = -1 - BlobService.UUID_STRING_LENGTH;
    return match.slice(uuidIndexStart, -1);
  }
  clear() {
    for (const blob of this.blobIds.keys()) {
      URL.revokeObjectURL(blob);
    }
  }
  static {
    this.ɵfac = function BlobService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BlobService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BlobService,
      factory: BlobService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BlobService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class ReleaseNotesButtonComponent {
  constructor() {
    this.modalService = inject(NgbModal);
    this.releaseNotesService = inject(ReleaseNotesService);
    this.blobService = inject(BlobService);
    this.subjectService = inject(SubjectService);
    this.userTrackingService = inject(UserTrackingService);
    this.icon = mdiFileDocumentEdit;
  }
  ngOnChanges() {
    if (this.applicationId) {
      const releaseNotes$ = this.releaseNotesService.getLatestReleaseNotesEntry({
        applicationId: this.applicationId
      }).pipe(shareReplay());
      releaseNotes$.pipe(switchMap$1(res => this.subjectService.resolveSubjectEndpointsWithParameters({
        app_id: 'overriden in interceptor',
        subject: 'global-release-notes-release-notes',
        parameters: {
          applicationId: this.applicationId,
          releaseNotesId: res.releaseNotes.id
        }
      })), catchError(() => this.subjectService.resolveSubjectEndpointsWithParameters({
        app_id: 'overriden in interceptor',
        subject: 'global-release-notes',
        parameters: {
          applicationId: this.applicationId
        }
      }))).subscribe(res => this.releaseNotesLink = res[0].endpointUrl);
      releaseNotes$.pipe(tap(res => this.isUnread = !res.read), switchMap$1(res => {
        if (res.read || !res.releaseNotes.popup) {
          return EMPTY;
        }
        return forkJoin({
          timer: timer(5000),
          releaseNotes: this.blobService.processUrlsInReleaseNotes(res.releaseNotes)
        });
      })).subscribe(({
        releaseNotes
      }) => {
        this.open(releaseNotes);
      });
    }
  }
  open(releaseNotes) {
    this.isUnread = false;
    this.releaseNotesService.markApplicationReleaseNotesAsRead({
      applicationId: this.applicationId
    }).subscribe();
    const modalRef = this.modalService.open(ReleaseNotesModalComponent, {
      size: 'xl',
      backdrop: 'static',
      scrollable: true
    });
    const openedAt = new Date().valueOf();
    modalRef.componentInstance.releaseNotes = releaseNotes;
    modalRef.closed.subscribe(() => {
      this.blobService.clear();
      this.userTrackingService.sendEvent({
        name: 'RN_CLOSE_RN_POPUP',
        customDetails: {
          app_id: this.applicationId,
          openFor: (new Date().valueOf() - openedAt) / 1000
        }
      });
    });
  }
  onClick() {
    this.userTrackingService.sendEvent({
      name: 'RN_OPEN_RN_FROM_APP',
      customDetails: {
        app_id: this.applicationId
      }
    });
  }
  static {
    this.ɵfac = function ReleaseNotesButtonComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ReleaseNotesButtonComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ReleaseNotesButtonComponent,
      selectors: [["aviatar-release-notes-button"]],
      inputs: {
        applicationId: "applicationId"
      },
      features: [i0.ɵɵProvidersFeature([ReleaseNotesService, BlobService]), i0.ɵɵNgOnChangesFeature],
      decls: 3,
      vars: 4,
      consts: [["role", "button", "target", "_blank", 1, "release-notes-button", "btn", "btn-link", "p-0", "position-relative", 3, "click", "href"], [3, "path", "inline"], [1, "position-absolute", "translate-middle", "p-1", "bg-primary", "rounded-circle"], [1, "visually-hidden"]],
      template: function ReleaseNotesButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "a", 0);
          i0.ɵɵlistener("click", function ReleaseNotesButtonComponent_Template_a_click_0_listener() {
            return ctx.onClick();
          });
          i0.ɵɵelement(1, "aviatar-icon", 1);
          i0.ɵɵtemplate(2, ReleaseNotesButtonComponent_Conditional_2_Template, 3, 0, "span", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("href", ctx.releaseNotesLink, i0.ɵɵsanitizeUrl);
          i0.ɵɵadvance();
          i0.ɵɵproperty("path", ctx.icon)("inline", true);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.isUnread ? 2 : -1);
        }
      },
      dependencies: [IconComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ReleaseNotesButtonComponent, [{
    type: Component,
    args: [{
      selector: 'aviatar-release-notes-button',
      imports: [IconComponent],
      providers: [ReleaseNotesService, BlobService],
      template: "<a role=\"button\" class=\"release-notes-button btn btn-link p-0 position-relative\" [href]=\"releaseNotesLink\" (click)=\"onClick()\" target=\"_blank\">\n    <aviatar-icon [path]=\"icon\" [inline]=\"true\"></aviatar-icon>\n\n    @if (isUnread) {\n        <span class=\"position-absolute translate-middle p-1 bg-primary rounded-circle\">\n            <span class=\"visually-hidden\">New release notes</span>\n        </span>\n    }\n</a>\n"
    }]
  }], null, {
    applicationId: [{
      type: Input,
      args: [{
        required: true
      }]
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ReleaseNotesButtonComponent };
