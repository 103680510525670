import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserTrackingEvent, UserTrackingService } from '@aviatar/user-tracking';
import { KeycloakService } from '@aviatar/sso';
import { CUSTOM_APP_CONFIG } from '@aviatar/configuration';
import { environment } from '@src/environments/environment';
import { ProcessService } from '@aviatar/api-client';
import { TaskDataState } from '@app/task-data/state-management/task-data.reducers';
import { select, Store } from '@ngrx/store';
import { TaskLoadInitializeAction } from '@app/task-data/state-management/task-data.actions';
import { hasErrors, isInitialized } from '@app/task-data/state-management/task-data.selectors';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Severity, UserNotificationService } from '@aviatar/user-notification';
import { LoadingSpinnerService } from '@app/core/services/loading-spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent implements OnInit {
  public title = 'Control Center';
  stage: string;
  applicationApiAppId: string;
  hasProcessStarters = false;

  constructor(private titleService: Title,
              private userTrackingService: UserTrackingService,
              private keycloakService: KeycloakService,
              @Inject(CUSTOM_APP_CONFIG) customConfig,
              private processStarterService: ProcessService,
              private taskDataStore: Store<TaskDataState>,
              private userNotificationService: UserNotificationService,
              private loadingSpinner: LoadingSpinnerService) {

    this.titleService.setTitle(this.title);
    this.stage = customConfig['deployment-stage'];
    this.applicationApiAppId = customConfig['application-api-app-id']
  }

  relevantStatusPageComponents = [
    'v03tmqt93vdk', // Control Center
    '9ztznwgb0w3t', // Event Based Actions
    'gtkbp0fjvvvt', // Honeywell DMO
    'hr5fyt12896k', // SHM
    '1tvq36j3mmrj', // PHA
    '1wnq3cv2l2kq', // Predictor Plugins
    '468kl6zm1w35' // Prediction Suite
  ];

  hasAcTroubleshootingAccess$ = this.keycloakService.hasResourceRole(environment.acTroubleshootingAccess.role, environment.acTroubleshootingAccess.resource);
  isTaskDataInitialized$: Observable<boolean>;

  ngOnInit(): void {
    this.loadingSpinner.show();

    this.taskDataStore.dispatch(new TaskLoadInitializeAction());

    this.isTaskDataInitialized$ = this.taskDataStore.pipe(
      select(isInitialized),
      tap(initialized => {
        if (initialized) {
          this.loadingSpinner.hide();
        }
      })
    );

    this.taskDataStore.select(hasErrors).pipe(
      filter(error => error === true)
    ).subscribe(_ => this.userNotificationService.addAlert({
      severity: Severity.WARNING,
      message: 'Could not load user tasks. The task inbox might be empty or incomplete.',
      dismissible: true
    }));

    // fixme: Send OPEN_APP event with a delay because amplitude needs to be initialize first. Should be fixed in user-tracking module.
    setTimeout(() => {
      const event: UserTrackingEvent = {
        name: 'CC_OPEN_APP'
      };
      this.userTrackingService.sendEvent(event);
    }, 4000);

    this.processStarterService.getProcessStarters({
      app_id: ''
    }).subscribe(starters => this.hasProcessStarters = starters.length > 0);
  }
}
